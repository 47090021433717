/* eslint eqeqeq: 0 */
/* eslint-disable react/prop-types */import './App.css';
import React,{useEffect,useState} from 'react';
import Community from './components/community';
import NavBar from './components/navbar';
import Topnews from './components/topnews'
import Article from './components/article'
import { XEmbed } from 'react-social-media-embed';
import { BrowserRouter, Routes, Route,Link } from 'react-router-dom';
import Footer from './components/footer'
import ArticleView from './components/articleView'
import NoticeView from './components/noticeView'
import Contacts from './components/contactsPage'
// import About from './components/about';
import Gallery from './components/gallery';
import FAQ from './components/faq';
import {fetchTwitterPost} from './Api'
import VideoTabs from './components/videoTabs'
import SpinWheel from './spinWheel'
import SurveyAdmin from './components/survey'
import SurveyCreator from './components/survey_creator'
import Videos from './components/videos'
import Downloads from './components/downloads'
import Deals from './components/deals'
import TestPage from './testpage'
import SurveyPageView from './components/surveyPage'
import SurveyAnalytics from './components/surveyAnalytics'

import TicketSurveyPageView from './components/ticketSurvey/surveyPage'
import TicketSurveyAdmin from './components/ticketSurvey/survey'
import TicketSurveyCreator from './components/ticketSurvey/survey_creator'
import TicketSurveyAnalytics from './components/ticketSurvey/surveyAnalytics'

import JobApplication from './components/jobApplication'
import Jobs from './components/jobs'
import Sub from './components/sub'
import VideoShorts from './components/videoView'
import Scholarship from './components/scholarship';
// import SingleSlider from './components/singleSlider'
import Letter from './components/letter'
// import ScrollToTopButton from './components/scrollToTopButton'
const baseUrl = window.location.pathname.split('/')[0];
const   Home = () => {
  // const [fbpost,setFbpost]=useState([])
  const [twpost,setTwpost]=useState([])
  // const [more,setMore]=useState(false);

  // const toggleMore=()=>{
  //   setMore(!more);
  // }
  useEffect(() => {
    // const getFacebookPost = async () => {
    //   try {
    //     // setLoading(true)
    //     const data = await fetchFacebookPost();
    //     setFbpost(data);
    //     // console.log(data);
    //       // setLoading(false)
    //   } catch (error) {
    //     // setLoading(false)
    //     console.log(error);
    //   }}
    //   getFacebookPost()
const getTwitterPost = async () => {
      try {
        // setLoading(true)
        const data = await fetchTwitterPost();
        setTwpost(data);
        // console.log(data);
          // setLoading(false)
      } catch (error) {
        // setLoading(false)
        console.log(error);
      }}
      getTwitterPost()

  }, []);
  // const isDarkModeInitial = JSON.parse(localStorage.getItem('isDarkMode')) || false;
  // const [isDarkMode, setIsDarkMode] = useState(isDarkModeInitial);
  // useEffect(() => {
  //   setIsDarkMode(isDarkMode)
  //   // Update localStorage whenever the state changes
  //   localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  // }, [isDarkMode]);
  // const [autoplay, setAutoplay] = useState(false);
  useEffect(() => {
    const handleIframeError = (event) => {
      const iframe = event.target;
      if (iframe) {
        const src = iframe.src;
        iframe.src = '';
        iframe.src = src; // Reload the iframe by setting the src to an empty string and then back to the original value
      }

    };

    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      iframe.addEventListener('error', handleIframeError);
    });

    return () => {
      // Clean up event listeners when the component unmounts
      iframes.forEach((iframe) => {
        iframe.removeEventListener('error', handleIframeError);
      });
    };

    
  }, []);
  // const [prods,setProds]=useState([])
  // const [banner,setBanner]=useState([])
  // useEffect(()=>{
  //   fetchPromotions().then(resp=>{
  //     setProds(resp)
  //   })
  //   fetchBanner().then(resp=>{
  //     setBanner(resp)
  //     console.log(resp)
  //   })
  // },[])
  return (
    <div >
      <NavBar title='home' />
    <Topnews/>
    
{/* <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
<div style={{width:'100%',maxWidth:1000,backgroundColor:'#f8f8f8',minHeight:200}}>
 <SingleSlider banner={banner}/>
</div>


</div>
<div style={{width:"100%",display:'flex',justifyContent:'center'}}>
<div style={{width:'100%',maxWidth:1360,marginTop:20}}>
<div style={{borderBottom:'2px solid #388E3C'}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',background:'#388E3C',width:220}}>Konnect Food+ Super Deals!</div>
      
    </div>
</div>
</div>

<div style={{width:"100%",display:'flex',justifyContent:'center'}}>
<div style={{width:'100%',maxWidth:1360,marginTop:20,display:'flex',justifyContent:"center",flexWrap:'wrap'}}>
  {prods.map((item,i)=>{
    return     <a target='_blank' rel="noreferrer" href={item.redirect_url} key={i} className="card link-unstyled" style={{maxWidth:250,width:'46%',margin:5,}}>
    <img src={'https://cdn.sasakonnect.net/content?bucket=landing-page&file='+item.main_image} alt={'img'} className="card-img-top" style={{maxHeight:300}}/>
    <div className="card-body" style={{display:'flex',flexDirection:'column',padding:10}}>
      <h5 className="card-title" style={{fontSize:16}}>{item.product_name}</h5>
      <p className="card-text"> <font style={{color:'red',fontSize:18}}>Ksh {item.selling_price.toLocaleString()} </font><font>    <del>Ksh {item.initial_price.toLocaleString()}</del> </font></p>
   <div style={{flex:1}}></div>
    <button className='btn btn-outline-success' style={{width:'100%'}}>Add to Cart</button>
    
    
    </div>
  </a>
    
    

   
  })}

</div>
</div> */}
    <Sub/>
        
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
   {/* <div onClick={()=>{toggleMore()}} style={{width:'100%',cursor:'pointer',maxWidth:1366,padding:10,lineHeight:'30px',color:'#1565C0',textAlign:'center',background:'#ECEFF1',borderRadius:10,marginTop:20,}}>
    {more==true ?'Hide More':'Load More'}
    </div> */}
   </div>
   {true && <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1366,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
  
 <div style={{flex:3,display:'flex',flexDirection:'column'}}>
   <Article/>
  <VideoTabs/>

<br/>

  {/* <div className='blink' style={{minHeight:200,minWidth:100,width:'100%',maxWidth:'100vw',flex:1,
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'contain',
        backgroundImage:`url(./images/banner.jpg)`}}>

        </div> */}
        {/* <img src={`./images/banner.jpg`} style={{minWidth:100,width:'50%',maxWidth:'100vw'}}/> */}
<br/>
     

      <div className="scrollable-div d-sm-none">
      <div style={{width:300,margin:10,marginTOp:20}}>
      {twpost?.length>0 && <XEmbed  url={twpost[0].tweet_id} />}

      </div>
   
      <div style={{width:300,margin:10,marginTop:20}}>
    
     {/* {fbpost?.length>0 &&  <FacebookEmbed url={fbpost[0].post_url} width={'100%'} />} */}
      </div>
       
        
    </div>


   
 </div> 


 
  <div className="my-div" style={{flex:1}}>

  <div style={{borderBottom:'2px solid black',paddingTop:20,marginLeft:10}}>
      <div style={{padding:5,lineHeight:'20px',color:'white',background:'black',width:150}}>Stay Connected</div>
      
    </div>
    <Link target="_blank"  rel="noreferrer" to='https://www.facebook.com/konnecthub'>  
    <div className='titlehover' style={{display:'flex',marginLeft:20,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={'./images/facebook.png'}/>Konnect Hub</div>
    <div style={{fontSize:14}}>Like</div>
    </div></Link>
    <Link target="_blank"  rel="noreferrer" to='https://twitter.com/KonnectInternet'>  
    <div className='titlehover' style={{display:'flex',marginLeft:20,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={'./images/twitter.png'}/>KonnectInternet</div>
    <div style={{fontSize:14}}>Follow</div>
    </div></Link>
    <Link target="_blank"  rel="noreferrer" to='https://www.youtube.com/@KaribuKonnect'>  
    <div  className='titlehover' style={{display:'flex',marginLeft:20,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
      <div style={{fontSize:14}}><img alt='' height={40} src={'./images/youtube.png'}/>KaribuKonnect</div>
      <div style={{fontSize:14}}>Subscribe</div>
    </div></Link>
    <Link target="_blank"  rel="noreferrer" to='https://instagram.com/konnecthub?igshid=NTc4MTIwNjQ2YQ=='>  
    <div className='titlehover' style={{display:'flex',marginLeft:20,flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:5}}>
    <div style={{fontSize:14}}><img alt='' height={40} src={'./images/insta.png'}/>Konnect Hub</div>
    <div style={{fontSize:14}}>Follow</div>
    </div>
    </Link>
    {/* <div className='blink' style={{minHeight:200,minWidth:100,width:'100%',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        backgroundSize:'contain',
        backgroundImage:`url(./images/banner.jpg)`}}>

        </div> */}
        <br/>
    {twpost?.length>0 && <XEmbed  url={twpost[0].tweet_id}/>}
     <br/>  <br/>
     {/* {fbpost?.length>0 &&  <FacebookEmbed url={fbpost[0].post_url} width={'100%'}  />} */}
        
  </div>
  
  </div>
    </div>
}



   <Footer/>
    </div>
  );
};


const   CommunityPage = () => {
  return (
    <div >
    <NavBar title='community' />
  {/* <Topnews/> */}

  <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1366,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
<div style={{flex:3,display:'flex',flexDirection:'column'}}>
<Community/>
</div> 
</div>
  </div>
  <Footer/>
  </div>
  )
  }
 
    const   LetterPage = () => {
      return (
        < >
        <NavBar title='community' />
      {/* <Topnews/> */}
    
      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
    
    <div style={{maxWidth:1000,width:'100%'}}>
    <div style={{flex:3,display:'flex',flexDirection:'column'}}>
    <Letter/>
    </div> 
    </div>
      </div>
      <Footer/>
      </>
      )
      }
const   ArticleViewPage = () => {
  return (
    < >
    <NavBar title='community' />
  {/* <Topnews/> */}

  <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1000,width:'100%'}}>
<div style={{flex:3,display:'flex',flexDirection:'column'}}>
<ArticleView/>
</div> 
</div>
  </div>
  <Footer/>
  </>
  )
  }
  const   NoticeViewPage = () => {
    return (
      < >
      <NavBar title='community' />
    {/* <Topnews/> */}
  
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
  
  <div style={{maxWidth:1000,width:'100%'}}>
  <div style={{flex:3,display:'flex',flexDirection:'column'}}>
  <NoticeView/>
  </div> 
  </div>
    </div>
    <Footer/>
    </>
    )
    }
  

const   GalleryPage = () => {
  return (
    <div >
    <NavBar title='gallery' />
  {/* <Topnews/> */}

  <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1000,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
<div style={{flex:3,display:'flex',flexDirection:'column'}}>
<Gallery/>
</div> 
</div>
  </div>
  <Footer/>
  </div>
  )
  }
  
    
const   FAQPage = () => {
  return (
    <div >
    <NavBar title='faq' />
  {/* <Topnews/> */}

  <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1000,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
<div style={{flex:3,display:'flex',flexDirection:'column'}}>
<FAQ/>
</div> 
</div>
  </div>
  <Footer/>
  </div>
  )
  }
      
const   VideosPage = () => {
  return (
    <div >
    <NavBar title='videos' />
  {/* <Topnews/> */}

  <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>

<div style={{maxWidth:1200,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
<div style={{flex:3,display:'flex',flexDirection:'column'}}>
<Videos/>
</div> 
</div>
  </div>
  <Footer/>
  </div>
  )
  }
  const   ScholarshipPage = () => {
    return (
      <div >
      <NavBar title='scholarship' />
    {/* <Topnews/> */}
  
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
  
  <div style={{maxWidth:1200,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
  <div style={{flex:3,display:'flex',flexDirection:'column'}}>
  <Scholarship/>
  </div> 
  </div>
    </div>
    <Footer/>
    </div>
    )
    }
  
  const   DealsPage = () => {
    return (
      <div >
      <NavBar title='deals' />
    {/* <Topnews/> */}
  
    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
  
  <div style={{maxWidth:1000,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
  <div style={{flex:3,display:'flex',flexDirection:'column'}}>
  <Deals/>
  </div> 
  </div>
    </div>
    <Footer/>
    </div>
    )
    }
  const   KonnectSurveyPage= () => {
    return (
      <div >
    
  <SurveyAdmin/>
 
    </div>
    )
    }

    const  TicketKonnectSurveyPage= () => {
      return (
        <div >
      
    <TicketSurveyAdmin/>
   
      </div>
      )
      }
    const   SurveyCreatorPage= () => {
      return (
        <div >

    <SurveyCreator/>
  
      </div>
      )
      }
      const   TicketSurveyCreatorPage= () => {
        return (
          <div >
  
      <TicketSurveyCreator/>
    
        </div>
        )
        }
    const   DownloadsPage= () => {
      return (
        <div >
        <NavBar  />
      {/* <Topnews/> */}
    
      <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
    
    <div style={{maxWidth:1000,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
    <div style={{flex:3,display:'flex',flexDirection:'column'}}>
    <Downloads/>
    </div> 
    </div>
      </div>
      <Footer/>
      </div>
      )
      }
      const   JobApplicationPage= () => {
        return (
          <div >
          <NavBar />

      <JobApplication/>
  
        <Footer/>
        </div>
        )
        }

        const   JobsPage= () => {
          return (
            <div >
            <NavBar />
  
        <Jobs/>
    
          <Footer/>
          </div>
          )
          }
          const   ContactsPage= () => {
            return (
              <div >
              <NavBar />
<div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center'}}>
    
    <div style={{maxWidth:600,width:'100%',background:'white',display:'flex',flexDirection:'row',minHeight:100}}>
    <div style={{flex:3,display:'flex',flexDirection:'column'}}>
    <Contacts/>
    </div> 
    </div>
      </div>
      <Footer/>
      </div>
             
          
            )
            }
  const   App = () => {
    return (
<div>
        <BrowserRouter>
        {/* <div> */}
        {/* <Route  path="/" element={< Navbar />} /> */}
      {/* <NavBar title='home' /> */}
      <Routes>
        <Route  path="/" element={<Home />} />
        <Route exact path="/community" element={<CommunityPage />} />
        <Route exact path="/community/:id" element={<ArticleViewPage/>} />
        <Route exact path="/notice/:id" element={<NoticeViewPage/>} />
        <Route exact path="/about" element={<aboutPage/>} />
        <Route exact path="/gallery" element={<GalleryPage/>} />
        <Route exact path="/faq" element={<FAQPage/>} />
        <Route exact path="/testpage" element={<TestPage/>} />
       <Route exact path="/wheel" element={<SpinWheel/>} /> 
       <Route exact path="/contact-us" element={<ContactsPage/>} /> 
        <Route exact path="/letter/:id" element={<LetterPage/>} /> 
        
        <Route exact path="/videos" element={<VideosPage/>} /> 
        <Route exact path="/deals" element={<DealsPage/>} /> 
        <Route exact path="/downloads" element={<DownloadsPage/>} /> 


          <Route exact path="/survey/admin" element={<KonnectSurveyPage/>} /> 
         <Route exact path="/survey/admin/edit/:id" element={<SurveyCreatorPage/>}/> 
          <Route exact path="/survey/view/:id" element={<SurveyPageView/>} />
           <Route exact path="/survey/admin/analytics/:id/:slug" element={<SurveyAnalytics/>} />

           <Route exact path="/ticket/survey/admin" element={<TicketKonnectSurveyPage/>} /> 
         <Route exact path="/ticket/survey/admin/edit/:id" element={<TicketSurveyCreatorPage/>}/> 
          <Route exact path="/ticket/survey/view/:id" element={<TicketSurveyPageView/>} /> 
          <Route exact path="/ticket/survey/view/:id/:ticket" element={<TicketSurveyPageView/>} /> 
         <Route exact path="/ticket/survey/admin/analytics/:survey/:ticket" element={<TicketSurveyAnalytics/>} />

           <Route exact path="/job/apply/:id" element={<JobApplicationPage/>} />
           <Route exact path="/jobs" element={<JobsPage/>} />
           <Route exact path="/verticle/videos" element={<VideoShorts/>} />
           <Route exact path="/Konnect-Beyond-Limit-Scholarship-2025" element={<ScholarshipPage/>} />
           <Route exact path="/not-found" element={<div style={{textAlign:'center'}}>
     
            <NavBar/>
            <div style={{paddingTop:60}}>
    <h1>Page Not Found</h1><br/>
            <img alt='img' src={baseUrl+'/images/404.webp'} style={{width:340}}   />

            </div>
            <div style={{paddingTop:60}}>
    <Footer/>

            </div>
            </div>}/>
     <Route path='*' element={<div style={{textAlign:'center'}}>
            <NavBar/>
            <div style={{paddingTop:60}}>
    <h1>Page Not Found</h1><br/>
            <img  alt='' src={baseUrl+'/images/404.webp'} style={{width:340}}   />

            </div>
            <div style={{paddingTop:60}}>
    <Footer/>

            </div>
            </div>}/>
       {/*
        //} />
        */}
      </Routes>
        {/* </div> */}
    </BrowserRouter>
  {/* <ScrollToTopButton/> */}
</div>

  

)
}
export default App;