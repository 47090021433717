import React,{useState} from 'react'
import {postEventApplication_2} from '../Api'
export default function eventApplication(props) {
    const [name, setName] = useState('');
   const [regionOthers, setRegionOthers] = useState('');
    const [phone, setPhone] = useState('');
    const [loading,setLoading]=useState(false)
    const [success,setSuccess]=useState(false)
    const [region, setRegion] = useState('');
    const [open_quiz_2, setOpen_quiz_2] = useState('');
     const [open_quiz_1, setOpen_quiz_1] = useState('');
    // const [open_quiz_1_other, setOpen_quiz_1_other] = useState('');
    const [open_quiz_3, setOpen_quiz_3] = useState('');
    // const [open_quiz_3_other, setOpen_quiz_3_other] = useState('');
    const [open_quiz_4, setOpen_quiz_4] = useState('');
    const [open_quiz_5, setOpen_quiz_5] = useState('');
    // const [open_quiz_6, setOpen_quiz_6] = useState('');
    // const [open_quiz_7, setOpen_quiz_7] = useState('');
    // const handleCheckboxChange = (setFunc) => (event) => {
    //   const { value, checked } = event.target;
    //   setFunc((prev) =>
    //     checked ? [...prev, value] : prev.filter((item) => item !== value)
    //   );
    // };
  
    // const handleRadioChange = (setFunc) => (event) => {
    //   setFunc(event.target.value);
    // };
  
    // const handleOtherChange = (setFunc) => (event) => {
    //   setFunc(event.target.value);
    // };
  
  // const [open_quiz_3, setOpen_quiz_3] = useState('');

  // const [selectedClasses, setSelectedClasses] = useState([]);

  // const handleCheckboxChange = (e) => {
  //   const selectedClasses2 = e.target.value;
  //   if (e.target.checked) {
  //     setSelectedClasses([...selectedClasses, selectedClasses2]);
  //   } else {
  //     setSelectedClasses(selectedClasses.filter(pkg => pkg !== selectedClasses2));
  //   }
  // };

    // const [message_quiz_1, setMessage_quiz_1] = useState('');
    // const [message_quiz_2, setMessage_quiz_2] = useState('');
    // const [message_quiz_3, setMessage_quiz_3] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
            setLoading(true)
          var data=  {
                "title": props.title,
                "phone": phone,
                "name": name,
                        "residency": region,
                "residency_other": regionOthers,
                  "open_quiz_1": open_quiz_1,
                  // open_quiz_1.includes('Other') ? [...open_quiz_1.filter(option => option !== 'Other'), `Other: ${open_quiz_1_other}`].join(',') : open_quiz_1.join(' & '),
 
            "open_quiz_2": open_quiz_2,

//  "open_quiz_4": open_quiz_4,
  "open_quiz_3": open_quiz_3,
  //.includes('Other') ? [...open_quiz_3.filter(option => option !== 'Other'), `Other: ${open_quiz_3_other}`].join(',') : open_quiz_3.join(' & '),
  "open_quiz_4": open_quiz_4,
  "open_quiz_5": open_quiz_5,
  // "open_quiz_6":open_quiz_6,
  // "open_quiz_7":open_quiz_7,
  // "message_quiz_1": message_quiz_1,
  // "message_quiz_2": message_quiz_2
                          }
                          console.log(data)
   postEventApplication_2(data).then((msg)=>{
        setName('')
        setRegionOthers('')
        setPhone('')
        setRegion('')
        setRegionOthers('')
      setOpen_quiz_2('')
         setOpen_quiz_1('')
        //  setOpen_quiz_1('')
        //  setOpen_quiz_2('')
        //  setOpen_quiz_1('')

          setOpen_quiz_4('') 
          setOpen_quiz_3('')
          // setOpen_quiz_4('')
          setOpen_quiz_5('')
          // setOpen_quiz_6('')
          // setOpen_quiz_7('')
        //  setOpen_quiz_3('')
        // setMessage_quiz_1('')
        // setOpen_quiz_1_other('')
        // setOpen_quiz_3_other('')
        // setMessage_quiz_2('')
        // setMessage_quiz_3('')

        setLoading(false)
        if(msg!=false){
            // handleButtonClick()
            setSuccess(true)
            // window.scrollTo(0, 0);
            // props.sentData(true)
        }
       

      
       })
     }
    //  const [showDiv, setShowDiv] = useState(false);
    //  const handleButtonClick = () => {

    //     setShowDiv(true);
    //         setTimeout(()=>{
    //       setShowDiv(false);
    //     },3000)
        
    //   }
  return (
    <div style={{width:'100%',maxWidth:800,backgroundColor:!success?'#e5e5e5':'white',padding:10,borderRadius:10}}>

<div style={{position:'fixed',width:'100%',left:0,top:100,display:'flex',flexDirection:'row',justifyContent:'center',zIndex:1000}}>

{/* {showDiv && (
    <div className={`alert alert-success slide-in ${showDiv ? 'show' : ''}`} role="alert">
        Application submitted successfully
   </div>

      )} */}
      </div>
      {success==false  && <form onSubmit={handleSubmit}>


     
        <h4>Participate in the Survey </h4>
        {/* <div className="form-group">
                  <label htmlFor="name" className="required">Name
                  
                  </label>
                  <input required value={name} onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Enter your full name"  />
                </div> */}

                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {/* <div className="form-group" style={{flex:1,minWidth:150}}>
                  <label htmlFor="phone" className="required">Phone Number</label>
                  <input required type="tel" maxLength={13} value={phone}  onChange={(e)=>{setPhone(e.target.value)}}  className="form-control" id="phone" placeholder="Enter your phone number"  />
                </div> */}
             
                </div>
            


    


    

<div className="form-group">
  <label htmlFor="region" className="required">   Region</label>
  <select required className="form-control" id="region" value={region} onChange={(e)=>{
    setRegion(e.target.value);
  }}>


<option value=''>-- --</option>
{[
    ['Githurai_44/45', 'Githurai 44/45'],
    ['Roysambu/Mirema', 'Roysambu/Mirema'],
    ['Zimmerman', 'Zimmerman'],
    ['Kahawa_West', 'Kahawa West'],
    ['Kahawa_Wendani', 'Kahawa Wendani'],
    ['Kasarani', 'Kasarani'],   
    ['Hunters/Mwiki/Sunton', 'Hunters/Mwiki/Sunton'],
    ['Lucky_Summer/Baba_Dogo/Ngumba', 'Lucky Summer/Baba Dogo/Ngumba'],
    ['kariobangi', 'Kariobangi'],
    ['Mowlem_Umoja_3', 'Mowlem/Umoja 3'],
    ['Umoja', 'Umoja'],
    ['Pipeline', 'Pipeline'],
    ['Kibera', 'Kibera']
  ].map(([value, label]) => (
    <option key={value} value={value}>{label}</option>
  ))}


    <option value="Other">Other</option>

  </select>
</div>
{region==='Other'     && <div className="form-group">
 <label htmlFor="regionOthers" >Specify residency if `Other` is selected

                  </label>
                  <input  value={regionOthers} onChange={(e)=>{setRegionOthers(e.target.value)}} type="text" className="form-control" id="regionOthers" placeholder=""  />
                </div>}
            
                <div className="form-group">
  <label className="required"><b>1. </b>Out of 10, how would you rate your overall experience at the customer extravaganza event?</label><br />
  
  <input
    type="radio"
    id="rating_very_dissatisfied"
    className="input_test"
    name="open_quiz_1"
    value="Very Dissatisfied"
    checked={open_quiz_1 === "Very Dissatisfied"}
    onChange={(e) => setOpen_quiz_1(e.target.value)}
  />
  <label htmlFor="rating_very_dissatisfied">Very Dissatisfied</label><br />

  <input
    type="radio"
    id="rating_dissatisfied"
    className="input_test"
    name="open_quiz_1"
    value="Dissatisfied"
    checked={open_quiz_1 === "Dissatisfied"}
    onChange={(e) => setOpen_quiz_1(e.target.value)}
  />
  <label htmlFor="rating_dissatisfied">Dissatisfied</label><br />

  <input
    type="radio"
    id="rating_neutral"
    name="open_quiz_1"
    value="Neutral"
    className="input_test"
    checked={open_quiz_1 === "Neutral"}
    onChange={(e) => setOpen_quiz_1(e.target.value)}
  />
  <label htmlFor="rating_neutral">Neutral</label><br />

  <input
    type="radio"
    className="input_test"
    id="rating_satisfied"
    name="open_quiz_1"
    value="Satisfied"
    checked={open_quiz_1 === "Satisfied"}
    onChange={(e) => setOpen_quiz_1(e.target.value)}
  />
  <label htmlFor="rating_satisfied">Satisfied</label><br />

  <input
    type="radio"
    className="input_test"
    id="rating_very_satisfied"
    name="open_quiz_1"
    value="Very Satisfied"
    checked={open_quiz_1 === "Very Satisfied"}
    onChange={(e) => setOpen_quiz_1(e.target.value)}
  />
  <label htmlFor="rating_very_satisfied">Very Satisfied</label><br />
</div>

<div className="form-group">
  <label className="required"><b>2. </b>What did you enjoy most about the event?</label><br />
  <input
    name="open_quiz_2"
    required
    value={open_quiz_2}
    onChange={(e) => setOpen_quiz_2(e.target.value)}
    rows="4"
       className="form-control"
    cols="50"
  />
</div>

<div className="form-group">
  <label className="required"><b>3. </b>What didn`t you like about the event?</label><br />
  <input
    name="open_quiz_3"
    required
    value={open_quiz_3}
       className="form-control"
    onChange={(e) => setOpen_quiz_3(e.target.value)}
    rows="4"
    cols="50"
  />
</div>

<div className="form-group">
  <label className="required"><b>4. </b>What could we improve for future events?</label><br />
  <input
    name="open_quiz_4"
    value={open_quiz_4}
    required
       className="form-control"
    onChange={(e) => setOpen_quiz_4(e.target.value)}
    rows="4"
    cols="50"
  />
</div>

<div className="form-group">
  <label className="required"><b>5. </b>How likely are you to recommend Konnect services to others?</label><br />
  
  <input
    type="radio"
    id="recommend_likely"
    name="open_quiz_5"
    value="Likely"
     className="input_test"
    checked={open_quiz_5 === "Likely"}
    onChange={(e) => setOpen_quiz_5(e.target.value)}
  />
  <label htmlFor="recommend_likely">Likely</label><br />

  <input
    type="radio"
    id="recommend_neutral"
    name="open_quiz_5"
     className="input_test"
    value="Neutral"
    checked={open_quiz_5 === "Neutral"}
    onChange={(e) => setOpen_quiz_5(e.target.value)}
  />
  <label htmlFor="recommend_neutral">Neutral</label><br />

  <input
    type="radio"
    id="recommend_unlikely"
    name="open_quiz_5"
     className="input_test"
    value="Unlikely"
    checked={open_quiz_5 === "Unlikely"}
    onChange={(e) => setOpen_quiz_5(e.target.value)}
  />
  <label htmlFor="recommend_unlikely">Unlikely</label><br />
</div>



     
    <div style={{float:'right'}}>
 {loading ?<button type="button" className="btn btn-warning"
             
           
             >Loading...</button>: <button type="submit" className="btn btn-warning"
             
           
             >Submit</button>}
    </div>
 
    {/* {success && <span style={{color:'green',paddingLeft:10,fontWeight:'bold'}}>
          
    Thank you for participating in the survey!
          </span>} */}
               </form>}
             {/* {success && <font style={{padding:10,fontSize:16}}>
              
              <p>Thank you for your feedback! Your input is invaluable for us to continue improving our service quality.</p>
              <p>
As our 4th anniversary celebration continues, be sure to check out the super deals from <a href="https://food.sasakonnect.net/10-weekly-offers"><b style={{color:'orange'}}>Konnect Food+ !</b></a> 
                </p>
<p>
  The Konnect Team,
</p>
</font>} */}
               {success && (
    <div className={`alert alert-success`} role="alert">
Thank you for taking the time to complete this survey.
   </div>

      )}
         {success==true &&<div style={{width:'100%',maxWidth:1200,float:'center',display:'flex'}}>
       <a href='../../'> <button className='btn btn-outline-primary' style={{fontSize:14}}>Homepage</button>
  
        </a>
        {/* <a href='https://food.sasakonnect.net/10-weekly-offers'> <button className='btn btn-warning' style={{marginLeft:20,position:"relative",fontSize:14}}>GET SUPER DEALS
        <img alt="img" width="25" src="/images/deal.png" style={{position: "absolute", right: -30}}></img>
        </button>
  
  </a> */}
        
         </div>}
<br/>
        {/* {success && <div style={{width:'100%',padding:5,display:'flex',flexWrap:"wrap",justifyContent:'space-around'}}>
        <a href='https://food.sasakonnect.net/food-cupboard/2815-cil-blended-rice-5kg-.html'>
<img src="../../images/promo_1.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
<a href='https://food.sasakonnect.net/drinks-beverages/313-fanta-passion-fruit-soda-2l.html'>
<img src="../../images/promo_2.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
<a href='https://food.sasakonnect.net/food-cupboard/677-kabras-sugar-2kg.html'>
<img src="../../images/promo_3.png"  style={{minWidth:320,width:"25vw",padding:3}} /></a>
        <div  style={{minWidth:320,width:"25vw"}} > </div>
         </div>} */}
       </div>
  )
}
